export const userRoles = {
    employee: "Employee",
    employer: "Employer",
    carrier: "Carrier",
    organisation: "Organisation",
    agent: "Agency",
    admin: "Admin",
    dependent: "Dependent"
};

export const customLoginPageDomain = ["billing.clearwaterhealth.com", "portal.veridyhealth.com"];

export const billingPortal = ["billing.clearwaterhealth.com"];

export const loginTitle = "Login";

export const clearwaterERLoginTitle = "Employer / Vendor Login";

export const clearwaterEELoginTitle = "Member Login";

export const employerButtonTitle = "Click Here for Employer / Vendor Login Page";

export const employeeButtonTitle = "Click Here for Member Login Page";

export const logoSize = { width: 258, height: 78 };

export const headerRowFontStyle = { name: 'Calibri', bold: true };

export const rowDataIndex = 8;

export const downloadFileTypes = {
    'pdf': 'pdf',
    'excel': 'excel'
};

export const groupbillExcelSheet1 = "Invoice Details";

export const groupbillExcelSheet2 = "GroupBill Summary";

export const billingPaymentType = {
    invoice: 'Invoice'
}

export const headerRowIndices = [9, 11, 12, 14]; // Row indices you want to style

export const colWidth = { other: 18, amount: 13 };

export const dependentTypes = {
    self: 'Self',
    spouse: 'Spouse'
}

export const paymentTypes = {
    cc: 'CC',
    ach: 'ACH'
}

export const tobaccoValues = {
    y: 'Y',
    n: 'N'
}

export const SSOLoginRedirectToDashboardConditions = ['', 'change-password', 'check-login'];
