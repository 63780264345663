import { ChangeDetectorRef, Component, HostListener, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { AuthService } from './core/http/auth/auth.service';
import { ThemeService } from 'src/app/core/services/theme.service';
import { LoaderScreenService } from './core/services/loaderScreen.service';
import { config as siteConfig } from 'src/sites/config';
import { Title } from '@angular/platform-browser';
import { LoaderConfigService } from './core/services/loader-config.service';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})

export class AppComponent implements OnDestroy {
    configurations: any = {};
    title = 'Member Portal';
    topBtn: boolean = false;
    isQuickEnroll: boolean = false;
    private readonly QUICK_ENROLL_PATH = 'quick-enroll';
    private destroy$ = new Subject<void>();
    constructor(
        private router: Router,
        private authService: AuthService,
        private themeService: ThemeService,
        public loaderScreenService: LoaderScreenService,
        private titleService: Title,
        private loaderConfigService: LoaderConfigService,
        private cdr: ChangeDetectorRef
    ) {
        this.configurations = siteConfig;
    }

    ngOnInit() {
        this.loaderConfigService.setQuickEnrollPageState(true);
        if (this.isAuthPage()) {
            this.loaderConfigService.setQuickEnrollPageState(false);
        }
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).pipe(takeUntil(this.destroy$)).subscribe(() => {
            const currentUrl = this.router.url;
            this.isQuickEnroll = currentUrl.includes(this.QUICK_ENROLL_PATH);
            this.loaderConfigService.setQuickEnrollPageState(this.isQuickEnroll);
            this.loaderScreenService.config.logoUrl = this.isQuickEnroll ? '' : this.configurations.logoUrl;
            if (!this.isQuickEnroll) {
                this.loaderConfigService.setQuickEnrollPageState(false);
            }
            this.cdr.detectChanges();
        });

        this.title = this.configurations.configuration.siteTitle;
        this.titleService.setTitle(this.title);
        this.loaderConfigService.isQuickEnrollPage$.subscribe(isQuickEnrollPage => {
            this.themeService.setTheme().then(() => {
                this.loaderScreenService.setLoaderConfigurations(isQuickEnrollPage).then((response: any) => {
                    this.cdr.detectChanges();
                });
            });
        });

        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
            //Check app pre-events

            //Check FirstLogin
            this.authService.checkFirstLogin(event['url']);
        });

        window.addEventListener('storage', (event) => {
            if (event.storageArea == localStorage) {
                let token = this.authService.getToken();
                if (token == undefined) {
                    this.authService.deleteToken();
                }
            }
        });
    }

    isAuthPage() {
        const url = window.location.href;
        return url.includes('login') || url.includes('forgot-password');
    }

    @HostListener("window:scroll", []) onWindowScroll() {
        this.scrollFunction();
    }

    scrollFunction() {
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
            this.topBtn = true;
        } else {
            this.topBtn = false;
        }
        //Sticky
        var header = document.getElementById("wrap-sticky-header");
        if (header) {
            var sticky = header.offsetTop;
            if (window.pageYOffset > sticky) {
                header.classList.add("sticky");
            } else {
                header.classList.remove("sticky");
            }
        }
    }

    topFunction() {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}